.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(37, 37, 37);
}

.App-header-home {
  background-color: transparent;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.WelcomeBar {
  display: flex;
  position: absolute;
  background-color: #3498db!important;
  width: 100%;
  top: 0px;
}

.WelcomeMenu{
  font-size: 16px;
  display: inline-table;
  width: 100%;
  text-align: left;
}

.WelcomeMenu .Selected{
  color: orange;
}

.WelcomeMenu button{
  color: white;
}

a {
  text-decoration: none;
}

.Home-description{
  font-size: 25px;
  padding: 20px 30px;
}

.Page-title {
  margin-bottom: 20px;
  padding: 0 30px;
  font-size: 27px;
  margin-top: 20px;
}

.Error-message {
  color: red;
  font-size: 20px;
  margin-bottom: 20px;
}

.Form-label {
  text-align: left;
  padding-left: 10px;
  width: 232px;
  font-size: 20px;
}

.Form-control input {
  font-size: 1rem;
  padding: 10px 10px;
  width: 260px;
}

.Form-control input {
  font-size: 1rem;
  padding: 10px 10px;
  width: 260px;
}

.Form-control.Register input {
  width: 100%;
}

.Form-control.Register .MuiFormControl-root {
  font-size: 1rem;
  padding: 10px 0;
  width: 100%;
}

.Form-control.Register select{
  padding-top: 10px;
  padding-bottom: 10px;
}


.Form-control .MuiInputBase-multiline {
  font-size: 1rem;
  width: 224px;
  height: 100px;
  display: block;
}

.Form-control .Form-control {
  width: 90%;
}

.Form-control.Register .Form-control.Register {
  width: 90%;
  max-width: 520px;
  font-size: 18px;
  padding: 10px 30px;
}

.Form-control.Register button.submit, .Form-control.Register button[type=submit]{
  width: 100%;
} 

.Form-control.Register.Half, .Form-control.AddClass.Half{
  padding: 10px 0;
}

.Form-control {
  height: auto;
  text-align: left;
}

.Form-control.Register{
  width: 90%;
  max-width: 520px;
  font-size: 18px;
}

.Form-control, .Form-control label {
  padding: 10px;
}

.Form-control label {
  width: 90px;
}

.Form-link.PasswordReset{
  text-decoration: underline;
}

.Form-link {
  text-align: center;
  padding-left: 10px;
  width: 232px;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Form-link a {
  color: rgb(37, 37, 37);
}

button.submit, button[type=submit]{
  font-size: 20px;
  padding: 10px;
  margin: 0;
  width: 280px;
}

.separator {
  margin-top: 60px;
}

.App-body{
  padding: 50px;
  width: calc(100% - 100px);
  min-height: calc(100vh - 164px);
}

.HomeCard{
  width: 100%;
  height: calc(50vh - 164px);
  background-color: #3498db;
  margin-bottom: 50px;
  color: white;
  text-shadow: 0px 2px 3px #1c5479;
  font-size: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.HomeCard:hover{
  background-color: #6badda;
}

.QrCodeBox{
  margin-top: 100px;
}

.QrCodeDownload{
  display: block;
  margin-top: 100px;
}

.QrCodeResult{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
}

.Qrcode-container video{
  max-width: 100%;
  max-height: 60vh;
}

@keyframes stripeTransform {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(100% * -1));
  }
}

.UserAvatar{
  float:right;
}

#QrCodeImage{
  display: none;
}